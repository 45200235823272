import * as React from "react"
import DownloadFilePage from "@/components/DownloadFilePage"
// TODO: 修改downloadURL
const Index = () => (
  <>
    <DownloadFilePage
      downloadURL={`${process.env.resourceUrl}docs/tos/XREAL_TERMS_OF_SERVICE_NL_nl_.pdf`}
    />
  </>
)

export default Index